import React, {useEffect, Component } from 'react';
import { Link, withRouter} from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import $ from 'jquery';
import { mdiChevronDown, mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
class GenerateDynamicMenu extends Component
{

    constructor(props) {
        super(props);
        this.state = {
          pathname:'',
        };
        let rw_pathname = window.location.pathname;
        if(rw_pathname!==null && rw_pathname!=='' && rw_pathname!==undefined)
        {
          rw_pathname=rw_pathname.substring(rw_pathname.lastIndexOf('/')+1,rw_pathname.length);
          this.state.pathname=rw_pathname;
        }
      }
	 

      toggleMenuState(menuState) {
        console.log('menuState:'+menuState);
        if (this.state[menuState]) {
          this.setState({[menuState] : false});
        } else if(Object.keys(this.state).length === 0) {
          this.setState({[menuState] : true});
        } else {
          Object.keys(this.state).forEach(i => {
           
            if(i!=null && i!=='' && i!==undefined && i!=='menuData')
            {
              this.setState({[i]: false});
            }
          });
          this.setState({[menuState] : true});
          //RW: Custom code start
          if(menuState==='userArtBooksMenuOpen' && !this.state[menuState])
          {
            this.setState({['userBooksMenuOpen'] : true});
          }
          //RW: Custom code end
        }
      }
      showHighlightedSelectedMainMenu(event)
      {
        event.preventDefault();
        $('.rwsidebar').find("*").removeClass("rwHighLight");
        event.currentTarget.className += " rwHighLight";
      }
      showHighlightedSelectedSubMenu(event)
      {
        //event.preventdefault();
        $('.nav.flex-column.sub-menu').find("*").removeClass("rwHighLight");
        event.currentTarget.className += " rwHighLight";
      }
	  

      
      render() {

        const { menuData } = this.props;
    
        return menuData.map((menu, key) => {
    
            return (
              menu.link==='#' ?
              
                  <li key={key} className={ this.isPathActive(menu.pathActive) ? 'nav-item active' : 'nav-item' } >
                  <div className={this.state[menu.state] ? 'nav-link menu-expanded' : 'nav-link' } onClick={ (event) => { this.showHighlightedSelectedMainMenu(event);this.toggleMenuState(menu.state)}  } data-toggle="collapse">
                    {/* <i className="mdi mdi-crosshairs-gps menu-icon"></i> */}
                    <i className={menu.menu_icon!==null?`${menu.menu_icon}`:''}></i>
                    <span className="menu-title" style={{cursor: "pointer"}} ><Trans>{menu.title}</Trans></span>
                    {/*<i className="menu-arrow"></i> */}  
                    {menu.submenu!==null?
                    <i><Icon path={mdiChevronDown} size={'20px'} color="#2d2d2d" /></i>
                    :''}
                  </div>
                  {menu.submenu ? 
                    
                  <Collapse in={this.state[menu.state] }>
                    <ul className="nav flex-column sub-menu">
                      {
                        menu.submenu.map((items, keys) => (
                        
                          <li key={keys} className="nav-item"> <Link onClick={(event) => this.showHighlightedSelectedSubMenu(event)} className={ this.isPathActive(items.pathActive) ? 'nav-link active' : 'nav-link' }  to={items.pathActive}><Trans>{items.title}</Trans></Link></li>
                        ))
                      }
                    </ul>
                    
                  </Collapse>
                  : '' }

                </li>

                :
                  <li
                    key={key}
                    className={
                      menu.title === 'Dashboard' && this.state.pathname === 'dashboard'
                        ? 'nav-item rwHighLight'
                        : 'nav-item'
                    }
                    onClick={(event) => this.showHighlightedSelectedMainMenu(event)}
                  >
                    <Link
                      className={this.isPathActive(menu.pathActive) ? 'nav-link active' : 'nav-link'}
                      to={menu.pathActive}
                      onClick={(e) => {
                        if (/^https?:\/\//.test(menu.pathActive)) {
                          window.open(menu.pathActive, '_blank');
                          e.preventDefault();
                        } else {

                          this.toggleMenuState(menu.state);
                        }
                      }}
                    >
                      <i className={menu.menu_icon !== null ? `${menu.menu_icon}` : ''}></i>
                      <span className="menu-title">
                        <Trans>{menu.title}</Trans>
                      </span>
                    </Link>
                  </li>

                
               );
          }) 
      }

      isPathActive(path) {
       return this.props.isPathActive(path);
      }
      
}
export default GenerateDynamicMenu
